import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from "../../../components/baseAPIUrl";

const initialState = {
  email: "",
};

class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = (event) => {
      this.setState({ [event.target.name]: event.target.value });
  };
  submitHandler(e) {
    e.preventDefault();
    }
  
  render() {
    if (this.props.token) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form
                      onSubmit={this.submitHandler}
                    >
                      <h1>Forget your account / password ?</h1>
                      <p className="text-muted">Type Your Registered Email</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Email"
                          autoComplete="email"
                          name="email"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
     
                      <Row>
                        <Col xs="6">
                          <Button
                            onClick={() =>
                              this.props.forgot({
                                email: this.state.email
                              })
                            }
                            color="primary"
                            className="px-4"
                          >
                            Send email
                          </Button>
                        </Col>
                        
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
    
              </CardGroup>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
  };
}
function mapDispatchToProps(dispatch) {
  return {
    forgot: (userInfo) => {
      axios
        // .post(`${baseUrl}/login/`, userInfo)
        .post(`${baseUrl}/ers_forgot_password/`, userInfo)
        .then((res) => {
          console.log(res.data);
          if(res.data){
            let varr = res?.data?.message
            console.log(varr)
            // console.log(res?.data?.message)
              toast(`Email Sent Successfully !! 💥`)
          }
          dispatch({ type: "Forgot", payload: res.data });
          
        })
        .catch((err) => {
            console.log(err.response)
            toast('Something wrong 💥')
        });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);