const initialState = {
  token: localStorage.getItem("ers_token"),
};

function LoginReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN": {
      return {
        responseData: action.payload.detail,
        token: action.payload.token,
      };
    }
    case "LOGOUT": {
      return {
        responseData: action.payload.detail,
        token: null,
      };
    }
    default:
      return state;
  }
}

export default LoginReducer;
